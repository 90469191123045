<template>
  <div style="margin-top: 60px;">
    <div class="multi-lang-privacy"> 
      <u  class="linguval-privacy">{{$t("privacy policy")}} </u>
    </div  >
    <div v-html="dataset"></div>
  </div>
  
</template>

<script>
export default {
 data() {
  return {
    dataset :''
  }
 },
mounted () { 
 document.title= "Privacy Policy: Privacy Statement - Ultra Gaana";
 var allMetaElements = document.getElementsByTagName('meta');
 for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
 if (allMetaElements[metaValue].getAttribute("name") == "description") { 
       allMetaElements[metaValue].setAttribute('content', "Please read about the privacy policy applying to personal information submitted by you or collected online from Ultra Gaana website."); 
  } 
 
 if(allMetaElements[metaValue].getAttribute("property") == "og:description") { 
 allMetaElements[metaValue].setAttribute('content', "Please read about the privacy policy applying to personal information submitted by you or collected online from Ultra Gaana website."); 
  } 

 if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
     allMetaElements[metaValue].setAttribute('content', "Please read about the privacy policy applying to personal information submitted by you or collected online from Ultra Gaana website."); 
  } 

 if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
     allMetaElements[metaValue].setAttribute('content', "privacy policy, privacy statement, Ultra Gaana privacy policy, Ultra Gaana privacy statement,"); 
  }

 if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
     allMetaElements[metaValue].setAttribute('content', "Privacy Policy: Privacy Statement - Ultra Gaana"); 
  }

 if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
     allMetaElements[metaValue].setAttribute('content', "Privacy Policy: Privacy Statement - Ultra Gaana"); 
  }
}    

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  fetch('https://static-templates.web.app/ultragaane/privacy/en.html').then((res) => {
    return res.text()
  }).then((result) => {
    this.dataset = result;
  })
 }
}
</script>

<style>
.multi-lang-privacy {
  text-align: center;
  color: white !important;
  font-size: 24px !important;
  font-weight:bold;
}
.linguval-privacy{
  position: relative;
  top:2px
}
</style>