<template>
     <div style="margin-top: 60px;">
    <div class="multi-lang"> 
      <u style="margin-top: 60px;" class="linguval">{{$t("TERMS OF USE")}} </u>
    </div  >
    <div v-html="dataset"> </div>
 
  </div>
  
  
</template>

<script>
export default {
 data() {
  return {
    dataset :''
  }
 },
 mounted () {
  document.title= "Terms and Conditions: Terms of Use - Ultra Gaana";   
  var allMetaElements = document.getElementsByTagName('meta');
  for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
  if (allMetaElements[metaValue].getAttribute("name") == "description") { 
       allMetaElements[metaValue].setAttribute('content', "Please read about the terms and conditions, terms of use, policies that apply to all subscribers, subscriptions from Ultra Gaana website"); 
  } 

  if (allMetaElements[metaValue].getAttribute("property") == "og:description") { 
    allMetaElements[metaValue].setAttribute('content', "Please read about the terms and conditions, terms of use, policies that apply to all subscribers, subscriptions from Ultra Gaana website"); 
  } 

  if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
     allMetaElements[metaValue].setAttribute('content', "Please read about the terms and conditions, terms of use, policies that apply to all subscribers, subscriptions from Ultra Gaana website"); 
  } 
  if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
     allMetaElements[metaValue].setAttribute('content', "terms and conditions, terms of use, terms of service, Ultra Gaana terms of use,"); 

  }
  if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
     allMetaElements[metaValue].setAttribute('content', "Terms and Conditions: Terms of Use - Ultra Gaana"); 
  }
  if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
     allMetaElements[metaValue].setAttribute('content', "Terms and Conditions: Terms of Use - Ultra Gaana"); 
  }

}

document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  fetch('https://static-templates.web.app/ultragaane/terms/en.html').then((res) => {
    return res.text()
  }).then((result) => {
    this.dataset = result;
  })
 }
}
</script>

<style>
.multi-lang{
  text-align: center;
  color: white;
  background-color: black;
  font-size: 24px !important;
  font-weight:bold;
}

.linguval{
   
    position: relative;
    top:20px
}

</style>